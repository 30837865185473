.pricing-card-price {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -50px;
        width: calc(100% + 67px);
        height: 100%;
        z-index: -1;
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
    }
}
