/* Menu-specific styles moved from rampagesoft.css to menu.scss */

/* Adjust container to center content and provide padding */
.mega__menu-wrapper .container {
    padding: 15px;
}

/* Ensure the toggle button aligns with the logo */
.mega__menu-wrapper .navbar-toggler {
    align-self: center;
    margin-left: auto;
}

/* Menu item styles */
.mega__menu-wrapper .nav-link,
.mega__menu-wrapper .dropdown-item {
    font-size: 15px;
    color: #000;
    font-weight: 600;
    padding: 10px 20px; /* Increase padding for more space inside each item */
    background-color: #d5f1ff; /* Light background for each menu item */
    border-radius: 8px; /* Smooth rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
    margin: 0 10px; /* Add horizontal space between menu items */
    transition: all 0.3s ease; /* Smooth transition effect */
}

/* Add hover effect for a more interactive feel */
.mega__menu-wrapper .nav-link:hover,
.mega__menu-wrapper .dropdown-item:hover {
    background-color: #b8e2f7; /* Slightly darker on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
    color: #205691; /* Change text color on hover */
    transform: translateY(-2px); /* Slight lift on hover */
}

/* Menu background and dropdown styles */
.mega__menu-wrapper .dropdown-menu {
    border: none;
    background-color: #f8f9fa;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.navbar-expand-lg .offcanvas .offcanvas-body {
    justify-content: center !important;
}

/* Logo adjustment */
.mega__menu-wrapper .navbar-brand img {
    height: 50px; /* Adjust based on your preference */
    width: auto;
    vertical-align: middle;
}

/* Close button for offcanvas */
.btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
}

.offcanvas-header {
    justify-content: center;
}

/* Mobile view */
@media only screen and (max-width: 600px) {
    .mega__menu-wrapper .nav-link,
    .mega__menu-wrapper .dropdown-item {
        font-size: 12px; /* Smaller font for mobile */
        padding: 6px 10px; /* Adjust padding for mobile */
    }

    .logomobile {
        width: 40px; /* Reduce the logo size for mobile */
    }
}
