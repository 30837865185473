/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .link-menu-so {
        font-size: 16px;
        font-weight: 600;
    }

    .text-company-af {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 11px;
        color: #000 !important;
    }

    .hovereffect h2 {
        font-size: 15px;
        padding: 1px;
    }

    .dis-footer {
        padding: 5px;
    }

    .posi-footer {
        justify-content: center;
    }

    .dis-footer ul {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .footer__address p {
        color: var(--rs-common-white);
        font-size: 11px;
        line-height: 22px;
    }

    .center-mo {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .justify-mo {
        justify-content: center;
    }

    .icon5-hw {
        height: 200px;
        width: 100%;
        background-size: cover;
        border-radius: 16px;
        background-position: center;
    }

    .garantee-res {
        padding-bottom: 40px;
    }

    .justify-garantee {
        padding-bottom: 20px;
        justify-content: center !important;
    }



    .head1-detallphoto {
        font-size: 24px;
        color: #b5d6e8;
        text-align: center;
        font-weight: 700;
        line-height: 1.2;
        padding-bottom: 25px;
    }

    .head2-detallphoto {
        font-size: 21px;
        color: #b5d6e8;
        line-height: 1.5;
        text-align: center;
        font-weight: 500;
        padding-bottom: 20px;
    }

    .pad-lr-100 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .text-spa-rp {
        color: #fff;
        padding-top: 15px;
        font-size: 18px;
        line-height: 1.7;
        font-weight: 500;
    }

    .head-bgbanner {
        color: #000;
        font-size: 34px;
        text-align: center;
        line-height: 55px;
    }

    .pricing-card-price.price-bg {
        text-align: center;
    }

    .pricing-card-body {
        padding: 20px;
    }


    .hovereffect img {
        display: block;
        position: relative;
        filter: brightness(0.6);
        -webkit-filter: brightness(0.6);
        transition: all 0.35s;

    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {


    .link-menu-so {
        font-size: 16px;
        font-weight: 600;
    }

    .text-company-af {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 11px;
        color: #000 !important;
    }

    .hovereffect h2 {
        font-size: 15px;
        padding: 1px;
    }

    .dis-footer {
        padding: 15px;
    }

    .posi-footer {
        justify-content: center;
    }

    .dis-footer ul {
        display: flex;
        justify-content: center;
    }

    .footer__address p {
        color: var(--rs-common-white);
        font-size: 11px;
        line-height: 22px;
    }

    .center-mo {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .justify-mo {
        justify-content: center;
    }

    .icon5-hw {
        height: 200px;
        width: 100%;
        background-size: cover;
        border-radius: 16px;
        background-position: center;
    }

    .garantee-res {
        padding-bottom: 40px;
    }

    .justify-garantee {
        padding-bottom: 20px;
        justify-content: center !important;
    }


    .head1-detallphoto {
        font-size: 24px;
        color: #b5d6e8;
        text-align: center;
        font-weight: 700;
        line-height: 1.2;
        padding-bottom: 25px;
    }

    .head2-detallphoto {
        font-size: 21px;
        color: #b5d6e8;
        line-height: 1.5;
        text-align: center;
        font-weight: 500;
        padding-bottom: 20px;
    }

    .pad-lr-100 {
        padding-left: 20px;
        padding-right: 20px;
    }

    .text-spa-rp {
        color: #fff;
        padding-top: 15px;
        font-size: 18px;
        line-height: 1.7;
        font-weight: 500;
    }

    .head-bgbanner {
        color: #000;
        font-size: 34px;
        text-align: center;
    }

    .pricing-card-price.price-bg {
        text-align: center;
    }

    .pricing-card-body {
        padding: 20px;
    }

    .hovereffect img {
        display: block;
        position: relative;
        -webkit-transition: all 0.35s;
        transition: all 0.35s;
       
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {


    .link-menu-so {
        font-size: 16px;
        font-weight: 600;
    }

    .text-company-af {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        color: #000 !important;
    }

    .hovereffect h2 {
        font-size: 20px;
        padding: 1px;
    }

    .dis-footer {
        padding: 15px;
    }

    .posi-footer {
        justify-content: center;
    }

    .dis-footer ul {
        display: flex;
        justify-content: center;
    }

    .footer__address p {
        color: var(--rs-common-white);
        font-size: 13px;
        line-height: 23px;
    }

    .dis-none-mo {
        display: block !important;
    }

    .center-mo {
        text-align: left;
    }

    .dis-res-nav {
        display: none !important;
    }

    .icon5-hw {
        height: 200px;
        width: 100%;
        background-size: cover;
        border-radius: 16px;
        background-position: center;
    }

    .garantee-res {
        padding-bottom: 0px;
    }

    .justify-garantee {
        padding-bottom: 20px;
        justify-content: center !important;
    }


    .head1-detallphoto {
        font-size: 30px;
        color: #b5d6e8;
        text-align: center;
        font-weight: 700;
        line-height: 1.2;
        padding-bottom: 25px;
    }

    .head2-detallphoto {
        font-size: 25px;
        color: #b5d6e8;
        line-height: 1.5;
        text-align: center;
        font-weight: 500;
        padding-bottom: 20px;
    }

    .pad-lr-100 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .text-spa-rp {
        color: #fff;
        padding-top: 15px;
        font-size: 17px;
        line-height: 1.7;
        font-weight: 500;
    }

    .head-bgbanner {
        color: #000;
        font-size: 34px;
        text-align: center;
    }

    .pricing-card-price.price-bg {
        text-align: center;
    }

    .pricing-card-body {
        padding: 20px;
    }

    .hovereffect img {
        display: block;
        position: relative;
        -webkit-transition: all 0.35s;
        transition: all 0.35s;
 
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {


    .link-menu-so {
        font-size: 16px;
        font-weight: 600;
    }

    .text-company-af {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: #000 !important;
    }

    .hovereffect h2 {
        font-size: 29px;
        padding: 1px;
    }

    .dis-footer {
        padding: 15px;
    }


    .posi-footer {
        justify-content: center;
    }

    .dis-footer ul {
        display: flex;
        justify-content: center;
    }

    .footer__address p {
        color: var(--rs-common-white);
        font-size: 16px;
        line-height: 27px;
    }

    .dis-none-mo {
        display: none !important;
    }

    .center-mo {
        text-align: left;
    }

    .dis-res-nav {
        display: block !important;
    }

    .icon5-hw {
        height: 250px;
        width: 100%;
        background-size: cover;
        border-radius: 16px;
        background-position: center;
    }

    .garantee-res {
        padding-bottom: 0px;
    }

    .justify-garantee {
        padding-bottom: 20px;
        justify-content: left !important;
    }


    .head1-detallphoto {
        font-size: 30px;
        color: #b5d6e8;
        text-align: center;
        font-weight: 700;
        line-height: 1.2;
        padding-bottom: 25px;
    }

    .head2-detallphoto {
        font-size: 25px;
        color: #b5d6e8;
        line-height: 1.5;
        text-align: center;
        font-weight: 500;
        padding-bottom: 20px;
    }

    .pad-lr-100 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .text-spa-rp {
        color: #fff;
        padding-top: 15px;
        font-size: 18px;
        line-height: 1.7;
        font-weight: 500;
    }

    .head-bgbanner {
        color: #000;
        font-size: 34px;
        text-align: center;
    }

    .pricing-card-price.price-bg {
        text-align: left;
    }

    .pricing-card-body {
        padding: 20px;
    }

    .hovereffect img {
        display: block;
        position: relative;
        -webkit-transition: all 0.35s;
        transition: all 0.35s;
   
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {


    .link-menu-so {
        font-size: 16px;
        font-weight: 600;
    }

    .text-company-af {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: #000 !important;
    }

    .hovereffect h2 {
        font-size: 29px;
        padding: 1px;
    }

    .dis-footer {
        padding: 15px;
    }


    .posi-footer {
        justify-content: left;
    }

    .dis-footer ul {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .footer__address p {
        color: var(--rs-common-white);
        font-size: 13px;
        line-height: 22px;
    }

    .dis-none-mo {
        display: none !important;
    }

    .center-mo {
        text-align: left;
    }

    .dis-res-nav {
        display: block !important;
    }

    .icon5-hw {
        height: 250px;
        width: 100%;
        background-size: cover;
        border-radius: 16px;
        background-position: center;
    }

    .garantee-res {
        padding-bottom: 0px;
    }

    .justify-garantee {
        padding-bottom: 20px;
        justify-content: left !important;
    }


    .head1-detallphoto {
        font-size: 30px;
        color: #b5d6e8;
        text-align: center;
        font-weight: 700;
        line-height: 1.2;
        padding-bottom: 25px;
    }

    .head2-detallphoto {
        font-size: 25px;
        color: #b5d6e8;
        line-height: 1.5;
        text-align: center;
        font-weight: 500;
        padding-bottom: 20px;
    }

    .pad-lr-100 {
        padding-left: 100px;
        padding-right: 100px;
    }

    .text-spa-rp {
        color: #fff;
        padding-top: 15px;
        font-size: 18px;
        line-height: 1.7;
        font-weight: 500;
    }

    .head-bgbanner {
        color: #000;
        font-size: 34px;
        text-align: center;
    }

    .pricing-card-price.price-bg {
        text-align: left;
    }

    .pricing-card-body {
        padding: 20px;
    }

    .hovereffect img {
        display: block;
        position: relative;
        -webkit-transition: all 0.35s;
        transition: all 0.35s;

    }
}




/* Extra large devices (large laptops and desktops, 1200px and up) */


.menu-topbar {
    background-color: #047ab7;
    padding: 5px 15px 5px 15px;
}

.logo img {
    width: 50%;
}

.img-bottom20 {
    padding-bottom: 20px;
}

.pad-20 {
    padding: 20px;
}

.pad-50 {
    padding: 50px;
}


.pad-lr-30 {
    padding-left: 30px;
    padding-right: 30px;
}

.pad-lr-50 {
    padding-left: 50px;
    padding-right: 50px;
}



.pad-tb-30 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pad-tb-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.pad-tb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pad-tb-150 {
    padding-top: 150px;
    padding-bottom: 150px;
}

.textcenter {
    text-align: center;
}

.mar-15 {
    margin-top: 15px;
}

.text-price {
    font-size: 59px;
}

.price-text-head h2 {
    font-size: 25px;
}


.price-text-red h2 {
    color: #be212f;
}

.price-text-blue h2 {
    color: #3dc1c2;
}

.price-text-gold h2 {
    color: #99a43b;
}

.bd-3b {
    border: 2px solid #3dc1c2;
}

.bd-3r {
    border: 2px solid #bf1f2f;
}

.bd-3g {
    border: 2px solid #99a43b;
}

.price-text-red .pricing-card-bodyy ul li:before {
    color: #be212f;
}

.price-text-blue .pricing-card-bodyy ul li:before {
    color: #3dc1c2;
}

.price-text-gold .pricing-card-bodyy ul li:before {
    color: #99a43b;
}


.price-text-red .pricing-card-button:before {
    background-color: #be212f;
}

.price-text-blue .pricing-card-button:before {
    background-color: #3dc1c2;
}

.price-text-gold .pricing-card-button:before {
    background-color: #99a43b;
}

.df-cen {
    display: flex;
    align-items: center;
}




.wd100 {
    width: 100%;
    padding: 0px;
}

.navbar-expand-lg .offcanvas .offcanvas-body {
    justify-content: center !important;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #eaeaea !important;
}

.navbar-toggler {
    border: 0;
}

.navbar-toggler-icon {
    width: 30px;
    height: 30px;
}

.navbar-nav {
    text-align: center;
}


.offcanvas {
    background: aliceblue;
}

.navbar-nav .dropdown-menu {
    text-align: center;
    font-size: 16px;
}

.text-head {
    text-align: center;
    color: #fff;
}


.bg-bklo {
    background: #272727 !important;
}

.bg-ss {
    background: #a6c6d7 !important;
}

.bg-menu-rampage {
    background: #b5d6e8 !important;
}

.bg-garantee {
    background: #9cccf1 !important;
}

.text-bk {
    color: #000 !important;
}

.bg-subk {
    background: #272727 !important;
}

.icon5-text {
    display: flex;
    justify-content: flex-end;
}

.button-arrow-icon5 {
    position: absolute;
    bottom: 10px;
    right: 10px;
}


.icon5-head {
    position: absolute;
    top: 20px;
    left: 20px;
}

.bg-black-swiper {
    background: #272727 !important;
}

.pad-tb-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.pad-b-30 {
    padding-bottom: 30px;
}



.text-detail-p {
    color: #fff;
    padding-top: 15px;
}

.button-deatil-p {
    background: black;
    display: flex;
    justify-content: center;
    border: 1px solid white;
    width: 230px;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.head1-garantee {
    font-size: 30px;
    color: #000;
    text-align: center;
    font-weight: 700;
}

.head2-garantee {
    font-size: 25px;
    color: #000;
    line-height: 1.5;
    text-align: center;
    font-weight: 700;
}

.p-garantee {
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: 700;
    line-height: 1.7;
}

.center-icon {
    display: flex;
    width: 8%;
    margin-left: auto;
    margin-right: auto;
}


.icon-button-garantee {
    font-size: 34px;
    color: #000;
}

.center-col {
    align-content: center;
}


.head2-detallphoto2 {
    font-size: 38px;
    color: #b5d6e8;
    line-height: 1.5;
    text-align: center;
    font-weight: 700;
}


.headsuccess {
    color: #fff;
}

.bg-success {
    background: #000 !important;
}

.box-border-su .swiper .swiper-wrapper .swiper-slide {
    border: 2px solid #000000;
    border-radius: 10px;
    background: #fff;
}

.box-su-pad {
    padding: 20px;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
}


.img-su-pad {
    padding: 5px;
}

.head-box-text {
    color: #000;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px !important;
}

.head-box-p {
    color: #000;
    font-size: 17px;
}


.accordion-button:not(.collapsed) {
    color: #fff;
}

.fqa-text h2 button {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    display: block;
    background: linear-gradient(180deg, black, #272e2e);
    color: #fff;
}

.head-fqa {
    color: #000;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
}

.accordion-body {
    font-size: 20px;
    padding: 30px;
    background: #252b2b;
    color: #fff;
    line-height: 1.5;
}

.accordion-button:not(.collapsed) {
    background-color: #1e8fdb
}

.accordion-item {
    margin-bottom: 20px;
}

.backttt {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.cr-text-detail2 {
    color: #1d9bf0;
}

.head1-detall2 {
    font-size: 30px;
    color: #000;
    text-align: center;
    font-weight: 700;
    line-height: 1.5;
}

.head2-detall2 {
    font-size: 38px;
    color: #000;
    line-height: 1.5;
    text-align: center;
    font-weight: 700;
}

.text-detail2-p {
    color: #000;
    padding-top: 15px;
}

.head1-spa {
    font-size: 30px;
    color: #000;
    text-align: center;
    font-weight: 700;
    line-height: 1.5;
}


.head2-spa {
    font-size: 38px;
    color: #000;
    line-height: 1.5;
    text-align: center;
    font-weight: 700;
}

.text-spa-p {
    color: #000;
    padding-top: 15px;
    font-size: 20px;
    line-height: 1.7;
    font-weight: 500;
}



/*EFF*/

.hovereffect {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;
}

.hovereffect .overlay {
    position: absolute;
    overflow: hidden;
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: scale(1);
    opacity: 1;
}

.hovereffect:hover .overlay {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}


.hovereffect:hover img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.6" /><feFuncG type="linear" slope="0.6" /><feFuncB type="linear" slope="0.6" /></feComponentTransfer></filter></svg>#filter');
    filter: brightness(0.6);
    -webkit-filter: brightness(0.6);
}

.hovereffect h2 {
    text-transform: uppercase;
    text-align: center;
    position: relative;
    background-color: transparent;
    color: #FFF;
    opacity: 1;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, 0, 0);
}

.hovereffect a,
.hovereffect p {
    color: #FFF;
    padding: 1em 0;
    opacity: 1;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 0, 0);
    text-decoration: underline;
}

.hovereffect:hover a,
.hovereffect:hover p,
.hovereffect:hover h2 {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.hovereffect:hover a {
    text-decoration: underline;
}

.swiper-horizontal>.swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
    position: sticky !important;
    margin-top: 20px;
}

.bg-success .container .swiper .swiper-scrollbar-drag {
    background: #fff;
    width: 300px !important;
}

.bg-success .container .swiper .swiper-scrollbar {
    background: rgb(255 255 255 / 12%);
}



.hovereffect .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg in oklab, #000 25%, #ff000000);
    z-index: 0;
    border-radius: 15px;
}


.accordion-button:not(.collapsed) {
    box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 #dee2e600;
}


.footer__pad {
    padding: 15px;
}

.header-top-socail-menu li {
    background: black;
    padding: 5px;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon5-head .btn {
    color: #1d1d1d;
    background-color: #0b5ed700;
    border-color: #0a58ca00;
    text-align: left;
}
.icon5-head .btn:hover {
    color: #1d1d1d;
    background-color: #0b5ed700;
    border-color: #0a58ca00;
}

.icon5-hw .btn-primary  {
    background-color: #0b5ed700;
    border-color: #0a58ca00;
}

 .btn-secondary {
    color: var(--bs-btn-hover-color);
    background-color: #d01b1b;
    border-color: #d01b1b;
}

.line-button .btn:hover  {
    color: #fff;
    background-color: #3df718;
    border-color: #3df718;
}


.btn-addline {
    color: #000;
    background-color: #3df718;
    border-color: #3df718;
}

.whatsapp-button .btn:hover  {
    color: #000;
    background-color: #29a71a;
    border-color: #29a71a;
}

.btn-whatsapp {
    color: #fff;
    background-color: #29a71a;
    border-color: #29a71a;
}


.img-responsive {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

